import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  Autocomplete,
  createFilterOptions,
  TextField,
  Typography,
  TextFieldProps,
} from "@mui/material";
import { FieldProps } from "./types";
import { useEffect, useState } from "react";

export interface FormAutocompleteProps extends FieldProps {
  labelKey: string;
  valueKey: string;
  multiple?: boolean;
  creatable?: boolean;
  index?: number;
  handleOnChange?: (newValue: any) => void;
  handleOnInputChange?: (newValue: any, index?: number) => void;
  disableFilter?: boolean; // Disable built-in filter to use API call for input search
  getUniqueOption?: boolean; // Ensure unique labels by combining valueKey and labelKey to handle possible duplicates
  disableOptionByKey?: string; // Disable options by key
  disableOptionByValue?: string | null | undefined; // Disable options by value
}

const filter = createFilterOptions<any>();

export const FormAutocomplete = ({
  id,
  name,
  label,
  required,
  options,
  labelKey,
  valueKey,
  multiple,
  creatable,
  defaultValue,
  readOnly,
  handleOnChange,
  handleOnInputChange,
  index,
  control,
  errors,
  register,
  rules,
  disableFilter,
  getUniqueOption,
  disableOptionByKey,
  disableOptionByValue,
}: FormAutocompleteProps): JSX.Element => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (inputValue) {
      const delayDebounceFn = setTimeout(() => {
        handleOnInputChange && handleOnInputChange(inputValue, index);
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <>
            <Autocomplete
              id={id}
              multiple={multiple}
              freeSolo={creatable}
              readOnly={readOnly}
              value={value || (multiple ? [] : "")}
              options={options || []}
              componentsProps={{ paper: { sx: { width: "fit-content" } } }}
              getOptionLabel={(option) =>
                getUniqueOption && option
                  ? `${option[valueKey]}-${option[labelKey]}`
                  : option?.[labelKey] ?? option
              }
              onInputChange={(e, newInputValue, reason) => {
                if (newInputValue && reason === "input") {
                  setInputValue(newInputValue);
                }
              }}
              isOptionEqualToValue={(option: any, value: any) => {
                return (
                  value === undefined ||
                  option === undefined ||
                  value === "" ||
                  option === value ||
                  option[valueKey] === value[valueKey]
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...(params as TextFieldProps)}
                  required={required}
                  label={label}
                />
              )}
              {...(register && register(name, rules))}
              onChange={(event, option) => {
                if (typeof option === "string" && option.includes("Add ", 0)) {
                  // Create a new value from the user input
                  onChange(option.split(" ")[1]?.replace(/^"|"$/g, ""));
                } else {
                  // Set default option
                  onChange(option);
                }
                if (handleOnChange) {
                  handleOnChange(option);
                }
              }}
              // https://next.material-ui.com/components/autocomplete/#search-as-you-type.
              // Restricted custom filter by using disableFilter flag
              filterOptions={(options, params) => {
                if (disableFilter) {
                  return options;
                } else {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option
                  );
                  if (inputValue !== "" && !isExisting && creatable) {
                    filtered.push(`Add "${inputValue}"`);
                  }
                  return filtered;
                }
              }}
              getOptionDisabled={(option) => {
                if (disableOptionByKey && disableOptionByValue) {
                  return option[disableOptionByKey] === disableOptionByValue;
                }
                return false;
              }}
            />
          </>
        )}
        shouldUnregister={true}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <Typography color="error">{message}</Typography>
        )}
      />
    </>
  );
};
